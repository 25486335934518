<template>
  <div class="apply">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">信贷产品申请</div>
    <div class="header">
        <div class="step">
            <div class="icon activebg">
                <img src="../../assets/img/applyicon1.png" alt="">
            </div>
            <div class="p active">企业信贷需求信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon2.png" alt="">
            </div>
            <div class="p">企业基本信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon3.png" alt="">
            </div>
            <div class="p">企业注册登记信息</div>
        </div>
        <div class="step">
            <div class="icon" style="padding-top: 1.5vw;">
                <img src="../../assets/img/applyicon4.png" alt="">
            </div>
            <div class="p">企业经营信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon5.png" alt="">
            </div>
            <div class="p">完成</div>
        </div>
    </div>
    <p class="title">企业申请信贷产品</p>
    <div class="card" v-for="(item,i) in requireInfo.productList" :key="item.productId">
        <div class="headline">{{item.creditName}}({{ item.bankName }})</div>
        <div class="catalog">
            <p>贷款银行<span class="key">*</span></p>
            <!-- <p>{{item.bankName}}</p> -->
            <div v-if="item.bankType==='Z'" class="select">
                <!-- <select v-model="requestData.bankCode[i]"> -->
                <!-- <input type="text" v-model="requestData.bankCode[i]" :value="item.bankCode"> -->
                <select disabled>
                    <option :value="item.bankCode" selected>{{item.bankName}}</option>
                </select>
            </div>
            <div v-else class="select">
                <select v-model="requestData.bankCode[i]">
                    <option v-for="item2 in item.bankList" :key="item2.ORG_CODE" :value="item2.ORG_CODE">{{item2.ORG_NAME}}</option>
                </select>
           </div>
        </div>
        <div class="catalog">
            <p>产品名称</p>
            <p>{{item.creditName}}</p>
        </div>        
        <div class="catalog">
            <p>产品编号</p>
            <p>{{item.productId}}</p>
        </div>
        <div class="catalog">
            <p>融资期限</p>
            <p>{{item.minYears ? item.minYears+'~'+item.maxYears + '个月': '无限制'}}</p>
        </div>        
        <div class="catalog">
            <p>更新时间</p>
            <p>{{item.updTime}}</p>
        </div> 
        
    </div>    
    <p class="title">企业信贷需求信息</p> 
    <div class="card">
        <div class="catalog">
            <p>币种<span class="key">*</span></p>
            <div class="select">
            <select v-model="requestData.currencyType">
                <option value="AUD">澳元</option>
                <option value="CAD">加元</option>
                <option value="CNY">人民币</option>
                <option value="EUR">欧元</option>
                <option value="GBP">英镑</option>
                <option value="HKD">港元</option>
                <option value="JPY">日元</option>
                <option value="MYR">林吉特</option>
                <option value="RUB">卢布</option>
                <option value="USD">美元</option>
            </select>
            </div>
        </div>
        <div class="catalog">
            <p>贷款金额(万元)<span class="key">*</span></p>
            <input type="number" v-model="requestData.loanAmt" oninput="if(value.length>10) value=value.slice(0,10)" placeholder="输入贷款金额">
        </div>
        <div class="catalog">
            <p>贷款期限(月)<span class="key">*</span></p>
            <input type="number" v-model="requestData.loanPeriod" oninput="if(value.length>3) value=value.slice(0,3)" placeholder="输入贷款期限">
        </div>
        <div class="catalog">
            <p>担保方式<span class="key">*</span></p>
            <div class="select">
            <select v-model="requestData.guaType">
                <option value="0">信用</option>
                <option value="1">抵押</option>
                <option value="2">保证</option>
                <option value="3">质押</option>
                <option value="4">其他</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>信贷需求种类<span class="key">*</span></p>
            <div class="select">                
            <select v-model="requestData.creditReqType">
                <option value="01">贷款</option>
                <option value="02">票据贴现</option>
                <option value="03">贸易融资</option>
                <option value="04">银行承兑汇票</option>
                <option value="05">信用证</option>
                <option value="06">保函</option>
                <option value="07">保理</option>
                <option value="99">其他</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>具体用途描述<span class="key">*</span></p>
            <textarea v-model="requestData.useDescr" placeholder="输入具体用途描述" maxlength="40"></textarea>
        </div>
        <div class="catalog">
            <p>可接受利率水平</p>
            <input type="number" v-model="requestData.rateLevel" maxlength="50" placeholder="输入可接受利率水平">
        </div>
        <!-- <div class="catalog">
            <p>机构信用数据授权查询时</p>
            <div class="applys">
            <van-radio-group v-model="checked" direction="horizontal">
            <van-radio name="1">一个月</van-radio>
            <van-radio name="2">两个月</van-radio>
            <van-radio name="3">三个月</van-radio>
            </van-radio-group>
            </div>
        </div> -->
        <div class="agree" @click="openAuth">
        <div class="agree2"></div>
        <van-checkbox v-model="agree" shape="square" icon-size="3.5vw">&nbsp;</van-checkbox>
        <p>我确认阅读授权事项，同意并接受<span style="color: red">《企业综合信息查询授权书》</span></p>        
        </div>     
    </div>
    <div style="text-align: center;padding: 4vw 0;">
        <div @click="nextStep()" class="next">下一步</div>
    </div>
    <!-- 企业综合信息查询授权书 -->
    <van-popup round v-model:show="showAuth">
        <div class="pop">
            <div class="pop-header">企业综合信息查询授权书</div>
            <div class="pop-content">
                <p>特别提醒：</p>
                <p style="text-indent:2em;">在您同意并接受《企业综合信息查询授权书》前，请您务必仔细阅读本授权书条款，一旦您点击“确定”按钮，即意味着您已充分理解所有条款的含义及相应的法律后果，同意向定向发送融资需求的或非定向发送融资需求的所有银行或其他金融机构给予本授权书的各项授权，且即使您的业务申请未被审核通过，本授权书各项授权的有效性不受影响。若您不同意授权，请直接退出申请页面。</p>
                <p>授权方：{{requireInfo.enterBaseInfo.enterName}}</p>
                <p>统一社会信用代码：{{requireInfo.enterRegisterInfo.uniscid}}</p>
                <p>被授权方：<span v-for="item in requireInfo.bankCodeList" :key="item">{{item.ORG_NAME}}<br></span></p><br/>
                一、授权内容<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;授权方同意并不可撤销的授权被授权方采集、查询、使用授权方在国家机关、政府部门、事业单位、金融机构、行业协会、社会团体等机构的相关信息。授权方同意无论融资业务申请是否获批，本授权书各项授权的有效性不受影响，且被授权方无需签退本授权书及其他业务申请资料。
                <br/><br/>
                二、授权目的、形式与期限<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（一）授权方知悉并同意本授权书以数据电文形式订立。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（二）授权内容相关信息用于授权方融资相关业务管理。本授权书自授权之日起生效，有效期持续至授权方基于本授权书向被授权方申请办理的融资业务结清全部欠款并终止授权方业务为止（截止之日止）。<br/>
               <br/>
                三、其他声明<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（一）授权内容相关信息仅供参考，对授权内容相关信息有歧义，双方应友好协商。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（二）授权方在此声明已知悉并理解本授权书，以及授权被授权方采集、查询、使用其非公开信息及负面信息可能导致的任何不利后果。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（三）本授权书是授权方向被授权方做出的单方承诺，在授权期限内不可撤销。<br/>
                <p style="float: right">授权时间：{{ (new Date()).toLocaleDateString() }}</p>
            </div>
            <div class="pop-footer">
                <div class="back" @click="closePop(1)">确认</div>
                <div class="back gray" @click="closePop(0)">取消</div>
            </div>
        </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { ref,reactive,toRaw,toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { apiApplyRequirement,extendJson } from '../../api/axios';
export default {
   setup() {
    const checked = ref('1');
    const agree = ref(false);
    let router = useRouter();    
    let store = useStore();
    let proId=router.currentRoute.value.query.proIds;
    const state = reactive({
        requireInfo: {},
        requestData: {
            bankCode:[],
            currencyType: 'CNY',
            loanAmt: '',
            loanPeriod: '',
            guaType:'0',
            creditReqType: '01',
            useDescr: '',
            rateLevel: '',
        }
    });
    
    if(proId === store.state.requireInfo.proIds) {
        state.requestData = extendJson(toRaw(state.requestData),store.state.saveFrontRequirement);
    }
    apiApplyRequirement({proIds: proId}).then(res => {                 
       if(res.code == '1'){
          state.requireInfo = res.info;
          res.info.productList.forEach((item,index)=>{
                if(item.bankType === 'Z') {
                    state.requestData.bankCode[index] = item.bankCode;
                }else {
                    state.requestData.bankCode[index] = '';
                }
          })         
        }
        else {
          Toast(res.msg);
        }
    }).catch(err=>{
        console.log(err,'请求错误') 
    });
    //授权书
    const showAuth = ref(false);
    const openAuth= () => {
        state.requireInfo.bankCodeList = [];
        let arr = toRaw(state.requestData.bankCode);
        for(let i= 0; i < arr.length; i++) {
            let obj = {ORG_NAME:'',ORG_CODE:''};
            if(state.requireInfo.productList[i].bankCode === arr[i]) {
                obj.ORG_NAME = state.requireInfo.productList[i].bankName;
                obj.ORG_CODE = state.requireInfo.productList[i].bankCode;
            }else if(arr[i]){
                obj = toRaw(state.requireInfo.productList[i].bankList).find(o => o.ORG_CODE === arr[i]);
            }
            state.requireInfo.bankCodeList.push(obj);
        }
        showAuth.value = true;
        // console.log(state.requireInfo.bankCodeList,999)
    };
    const closePop= (i) => {
        if(i) {
            agree.value = true;
        }else {
            agree.value = false;
        }        
        showAuth.value = false;
    };
    const nextStep= () => {
        let bankCodeBool = true;
        state.requestData.bankCode.forEach((item)=>{
            if(item=='') {
                bankCodeBool = false;
            }
        });
        if(!bankCodeBool) {
            Toast('请先选择贷款银行！');
            return;
        }
        if(!state.requestData.loanAmt) {
            Toast('请输入贷款金额！')
            return;
        }
        if(!state.requestData.loanPeriod) {
            Toast('请输入贷款期限！')
            return;
        }
        if(state.requestData.useDescr.length <1) {
            Toast('请输入不超过40个字符的具体用途描述！')
            return;
        }
        if(!agree.value) {
            Toast('请先确认阅读授权事项！')
            return;
        }
        state.requireInfo.bankCodeList = [];
        let arr = toRaw(state.requestData.bankCode);
        for(let i= 0; i < arr.length; i++) {
            let obj = {ORG_NAME:'',ORG_CODE:''};
            if(state.requireInfo.productList[i].bankCode === arr[i]) {
                obj.ORG_NAME = state.requireInfo.productList[i].bankName;
                obj.ORG_CODE = state.requireInfo.productList[i].bankCode;
            }else {
                obj = toRaw(state.requireInfo.productList[i].bankList).find(o => o.ORG_CODE === arr[i]);
            }
            state.requireInfo.bankCodeList.push(obj);
            // console.log(obj)
        }
        // console.log(state.requestData.bankCode,999)
        const reqInfo = toRaw(state.requireInfo);
        reqInfo.proIds = proId;
        reqInfo.routerName = 'apply';
        store.dispatch('setRequireInfo',reqInfo);
        
        let proIdArr = [];
        let creditNameArr = [];
        let onlineFlagArr = [];
        reqInfo.productList.forEach((item)=>{
                proIdArr.push(item.productId);
                creditNameArr.push(item.creditName)
                onlineFlagArr.push(item.onlineFlag)
        });
        state.requestData.productIds = proIdArr.toString();
        state.requestData.creditName = creditNameArr.toString();
        state.requestData.onlineFlag = onlineFlagArr.toString();
        store.dispatch('setRequestData',toRaw(state.requestData));

        router.push({
            name:'applyB',
            replace:true,
            // query: {proIds: proId}
        });
    };
    const goBack=() => {
            // router.push({name: 'loanProduct'});
            store.dispatch('setRequireInfo',{});
            store.dispatch('setRequestData',{});
            router.go(-1);
    };
    return {
        checked,
        agree,
        ...toRefs(state),
        showAuth,
        openAuth,
        closePop,
        nextStep,
        goBack
    };
  },

}
</script>
<style lang="less" scoped>
.apply {
    padding-top: 12.5vw;
.header {
    margin: 4vw;
    background-color: #fff;
    border-radius: 1.5vw;
    padding: 4vw;
    display: flex;
    justify-content: space-between;
    .step {
        width: 13vw;
        .icon {
            width: 100%;
            height: 13vw;
            background-color: #cccccc;
            border-radius: 50%;
            text-align: center;
            line-height: 13vw;
            box-sizing: border-box;
            img {
                transform: scale(0.5);
            }
        }
        .p {
            color: #666666;
            font-size: 3.1vw;
            text-align: center;
            margin-top: 2vw;
        }
        .activebg {
            background-color: #ff9900;
        }
        .active {
            color: #ff9900;
        }
    }
}
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    
    .card {
        padding: 2vw 4vw;
        .headline {
            background-color: #fdf8ed;
            color: #ff9900;
            font-size: 4vw;
            padding: 2vw;
            border-radius: 1.5vw;
        }
        .catalog {
            display: flex;
            align-items: center;
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;
                // padding: 3vw 0;
            }
            select,input,textarea {
                background-color: #f4f8fe;
                border: 0;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
                margin-left: 2vw;
            }
            textarea {
                resize: none;
                height: 15vw;
            }
            .applys {
                width: 55vw;
                margin-left: 2vw;
            }

        }
        .agree {
            margin: 2vw 0;
            font-size: 3.5vw;
            color: #666;
            display: flex;
            align-items: baseline;
            position: relative;
            .agree2 {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 999;
            }        
        }
    }
    .next {
        display: inline-block;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 4vw;
        padding: 1.5vw 5vw;
        border-radius: 5vw;
    }
    .pop {
        padding: 4vw;
        width: 70vw;
        .pop-header {
            font-size: 5vw;
            font-weight: 600;
            text-align: center;
            margin-bottom: 4vw;
        }
        .pop-content {
            width: 100%;
            height: 60vh;
            font-size: 4vw;
            overflow-y: auto;
            line-height: 6vw;
            letter-spacing: 1px;
            p {
                font-weight: bold;
            }
        }
        .pop-footer {
            text-align: center;padding-top: 4vw;
            .back {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                width: 22vw;
                margin: 0 2vw;
            }
            .gray {
                background: linear-gradient(to right, #989898, #686868);
            }
        }
    }
    
}
</style>
<style lang="less">

    .applys {
        p {
            font-weight: 600;
            font-size: 4vw;
        }
        .van-radio__icon--checked .van-icon,
        .van-radio__icon .van-icon {
            display: none;
        }
        .van-radio--horizontal {
            margin-right: 2vw;
        }
        .van-radio__label {
            background-color: #f4f8fe;
            font-size: 4vw;
            padding: 2vw 2vw;
            border-radius: 1vw;
            margin: 2vw 0 0 0;
        }
        .van-radio[aria-checked="true"] .van-radio__label {
            color: #fff;
            background-color: #ff9900; 
        }
    }


</style>